body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #78746f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

.navbar-nav {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  background-color: #3d392f;
  height: 80px;
  padding: 48px 64px;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-btn {
  background-color: #3d392f;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 1.25rem;
}

.navbar-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: #595447;
  cursor: pointer;
}

.navbar-btn-text {
  font-weight: 600;
  font-size: 1.4rem;
  color: #faec98;
}

.navbar-logotext, .navbar-navs {
  margin: 0;
}

.navbar-logotext {
  margin-right: auto;
  color: #f5f0e4;
  font-weight: 600;
  font-size: 1.5rem;
}

.navbar-navs {
  color: #ffd5a1;
  font-weight: 600;
  font-size: 1.5rem;
  display: inline-flex;
}

.navbar-icon {
  height: 5rem;
  margin-right: 7px;
}



.mobile-navbar-nav {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d392f;
  height: 10vh;
  padding: 1vh 1vh;
  position: sticky;
  top: 0;
  z-index: 999;
}

.mobile-navbar-btn {
  background-color: #3d392f;
  border-radius: 1vh;
  outline: none;
  border: none;
  padding: 1vh;
}

.mobile-navbar-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: #595447;
  cursor: pointer;
}

.mobile-navbar-btn-text {
  font-weight: 600;
  font-size: 4vw;
  color: #faec98;
}

.scroll-btn-container {
  position: absolute;
  left: 50%;
  margin-left: -21px;
}

.arrow {
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  border-style: solid;
  border-color: white;
  border-width: 0px 3px 3px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.arrow:hover {
  border-bottom-width: 5px;
  border-right-width: 5px;
  cursor: pointer;
}

.aboutme-wrapper{
  align-items: center;
  max-width: 1000px;
  max-height: 500px;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 5rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.mobile-aboutme-wrapper{
  width: 70vw;
  margin-left: 8vw;
  margin-top: 10vw;
  max-height: 80vh;
  font-weight: 400;
  font-size: 1.5rem;
  min-height: 100vh;
}

.resumeContainer-wrapper{
  align-items: center;
  max-width: 1000px;
  max-height: 500px;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 10rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.mobile-resumeContainer-wrapper{
  align-items: center;
  max-width: 1000px;
  max-height: 500px;
  margin: auto;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  flex: 100%;
  margin-top: 0rem;
}

.itemselector-wrapper{
  align-items: center;
  max-width: 50%;
  min-width: 50%;
  max-height: 500px;
  margin: auto;
  margin-bottom: 0rem;
  margin-top: 10rem;
  margin-left: 2rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.itemselector-line{
  color: #f5f0e4;
  line-height: 150%;
  font-weight: 400;
  font-size: 1vw;
  margin: 0rem 1rem 1rem 0rem;
}

.mobile-itemselector-wrapper{
  align-items: center;
  width: 80vw;
  max-height: 80vh;
  font-weight: 400;
  font-size: 1.5rem;
}

.mobile-itemselector-line{
  color: #f5f0e4;
  line-height: 150%;
  font-weight: 400;
  font-size: 1.5vh;
  margin: 0rem 0rem 0rem 0rem;
  padding: 1vh 1vh 1vh 1vh;
}
.aboutme-line {
  color: #f5f0e4;
  line-height: 150%;
  font-weight: 400;
  font-size: 1vw;
  margin: 1rem 1rem 1rem 1rem;
}

.mobile-aboutme-line {
  color: #f5f0e4;
  line-height: 150%;
  font-weight: 400;
  font-size: 1.75vh;
  margin: 1rem 1rem 1rem 1rem;
}

.aboutme-name {
  color: #faec98;
  font-weight: 600;
  font-size: 2.5vw;
  padding: 1rem 0rem 0rem 1rem;
  margin: 0% 0%;
}

.mobile-aboutme-name {
  color: #faec98;
  font-weight: 600;
  font-size: 5vw;
  padding: 1rem 0rem 0rem 1rem;
  margin: 0% 0%;
}

.aboutme-subname {
  color: #faec98;
  font-weight: 400;
  font-size: 2vw;
  padding: 1rem 0rem 0rem 1rem;
  margin: 0% 0%;
}

.mobile-aboutme-subname {
  color: #faec98;
  font-weight: 400;
  font-size: 4vw;
  padding: 1rem 0rem 0rem 1rem;
  margin: 0% 0%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #3d392f;
  padding: 1rem;
}

.mobile-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #3d392f;
  padding: 2vw;
}

.itemselector-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #3d392f;
  padding: 1rem;
  min-height: 23.5rem;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.lightcard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #78746f;
  padding: 1rem;
}

.lightcard:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.itemselector-display-wrapper{
  display: flex;
}

.mobile-itemselector-display-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1.0rem;
  margin: 0rem 1.5rem 1rem 0rem;
  padding: 0rem 0rem 0rem 1rem;
}

.mobile-buttons-wrapper{
  display: flex;
  flex-direction: row;
  gap: 1.0vh;
  margin: 0rem 0vh 1vh 0rem;
  padding: 0vh 0vh 0vh 1vh;
  align-self: center;
}

.button-selector {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  text-align: center;
  background-color: #f5f0e4;
  font-weight: 400;
  font-size: 1vw;
  padding: 0rem 1rem;
  width: 13vw;
}

.mobile-button-selector {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 1.5vw;
  text-align: center;
  background-color: #f5f0e4;
  font-weight: 400;
  font-size: 2.25vw;
  width: 21vw;
  outline: none;
}

.button-selector:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: #ffd5a1;
  cursor: pointer;
}

.mobile-button-selector:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: #ffd5a1;
  cursor: pointer;
}


